import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';

import { toast } from 'react-toastify';
import AuthContext from '../../authContext';

const ShareDocumentModal = ({ show, handleClose, documentId,  documentName, menuId }: { show: boolean, handleClose: () => void, documentId: string, documentName: string, menuId: string }) => {
    const authContext = useContext(AuthContext);
    const [institutions, setInstitutions] = useState([]);
    const [selectedInstitution, setSelectedInstitution] = useState("");
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (show) {
            fetchInstitutions();
        }
    }, [show]);

    const fetchInstitutions = async () => {
        try {
            const config = {
                method: 'post',
                url: `${process.env.REACT_APP_API}/pipeline`,
                headers: {
                    'x-access-token': authContext.token,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    "filter": {
                        "filter": {
                            "logic": "and",
                            "offset": 0,
                            "limit": 25,
                            "filters": [],
                            "sort": [
                                {
                                    "field": "id",
                                    "table": "Institute",
                                    "dir": "desc"
                                }
                            ]
                        }
                    },
                    "menuId": menuId,
                    "action": "command",
                    "command": [
                        {
                            "agent": "institutionList",
                            "appName": "fileSharing",
                            "folder": "userManagement/institution"
                        }
                    ]
                })
            };
            const response = await axios.request(config);
            setInstitutions(response.data.data);
        } catch (error) {
            toast.error('Failed to fetch institutions');
        }
    };

    const handleInstitutionChange = (e: { target: { value: any; }; }) => {
        const institutionId: any = e.target.value;
        setSelectedInstitution(institutionId);
        const institution: any = institutions.find((inst: any) => { return inst.id === parseInt(institutionId) });
        if (institution) {
            setEmail(institution.email);
        }
    };

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const config = {
                method: 'post',
                url: `${process.env.REACT_APP_API}/pipeline`,
                headers: {
                    'x-access-token': authContext.token,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    "documentId": documentId,
                    "instituteId": selectedInstitution,
                    "menuId": menuId,
                    "email": email,
                    "action": "command",
                    "command": [
                        {
                            "agent": "sharedDocument",
                            "appName": "fileSharing",
                            "folder": "documentManagment"
                        }
                    ]
                })
            };
            const response = await axios.request(config);
            if (response.status === 200) {
                if (response.data.status === 'success') {
                    toast.success(response.data.message || 'Document shared successfully');
                    handleClose();
                } else {
                    toast.error(response.data.message || 'Failed to share document');
                }

            } else {
                toast.error(response.data.message || 'Failed to share document');
            }
        } catch (error: any) {
            toast.error('Something went wrong! ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Share Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>

                    <Form.Group controlId="formEmail">
                        <Form.Label className='form-label'><span className='text-danger'>*</span>&nbsp;Document Name</Form.Label>
                        <Form.Control className='form-control disabled' type="text" value={documentName} disabled={true} readOnly />
                    </Form.Group>
                    <Form.Group controlId="formInstitution">
                        <Form.Label className='form-label'><span className='text-danger'>*</span>&nbsp;Select Institution</Form.Label>
                        <Form.Control className='form-control' as="select" value={selectedInstitution} onChange={handleInstitutionChange}>
                            <option value="">Select an institution...</option>
                            {institutions.map((institution: { id: string, name: string }) => (
                                <option key={institution.id} value={institution.id}>
                                    {institution.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <br>
                    </br>
                    <Form.Group controlId="formEmail">
                        <Form.Label className='form-label'><span className='text-danger'>*</span>&nbsp;Email</Form.Label>
                        <Form.Control className='form-control' type="email" disabled={true} value={email} readOnly />
                    </Form.Group>
                    <br>
                    </br>
                    <Button variant="primary" type="submit" disabled={isLoading}>
                        {isLoading ? 'Sharing...' : 'Share Document'}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal >
    );
};

export default ShareDocumentModal;
