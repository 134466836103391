import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';

import { toast } from 'react-toastify';
import AuthContext from '../../authContext';

const DirectShareDocument = ({ show, handleClose, documentId, documentName, menuId }: { show: boolean, handleClose: () => void, documentId: string, documentName: string, menuId: string }) => {
    const authContext = useContext(AuthContext);
    const [institutions, setInstitutions] = useState([]);
    const [selectedInstitution, setSelectedInstitution] = useState("");
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);



    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const config = {
                method: 'post',
                url: `${process.env.REACT_APP_API}/pipeline`,
                headers: {
                    'x-access-token': authContext.token,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    "documentId": documentId,
                    "email": email,
                    "menuId": menuId,
                    "action": "command",
                    "command": [
                        {
                            "agent": "sharedDocumentDirect",
                            "appName": "fileSharing",
                            "folder": "documentManagment"
                        }
                    ]
                })
            };
            const response = await axios.request(config);
            if (response.status === 200) {
                if (response.data.status === 'success') {
                    toast.success(response.data.message || 'Document shared successfully');
                    handleClose();
                } else {
                    toast.error(response.data.message || 'Failed to share document');
                }

            } else {
                toast.error(response.data.message || 'Failed to share document');
            }
        } catch (error: any) {
            toast.error('Something went wrong! ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Share Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                
                <Form.Group controlId="formEmail">
                    <Form.Label className='form-label'>Document Name</Form.Label>
                    <Form.Control className='form-control' type="text" value={documentName} readOnly />
                </Form.Group>
                {/* <Form.Group controlId="formInstitution">
                    <Form.Label className='form-label'>Select Institution</Form.Label>
                    <Form.Control className='form-control' as="select" value={selectedInstitution} onChange={handleInstitutionChange} disabled={true}>
                        <option value="">Select an institution...</option>
                        {institutions.map((institution: { id: string, name: string }) => (
                            <option key={institution.id} value={institution.id}>
                                {institution.name}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group> */}
                <br>
                </br>
                <Form.Group controlId="formEmail">
                    <Form.Label className='form-label'>Email</Form.Label>
                    <Form.Control className='form-control' type="email" onChange={(e) => setEmail(e.target.value)} placeholder='Enter email' />
                </Form.Group>
                <br>
                </br>
                <Button variant="primary" type="submit" disabled={isLoading}>
                    {isLoading ? 'Sharing...' : 'Share Document'}
                </Button>
            </Form>
        </Modal.Body>
        </Modal >
    );
};

export default DirectShareDocument;
