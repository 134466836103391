import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../../../authContext";
import { toast } from "react-toastify";
import Shimmer from "../../Shimmer";
import { useNavigate, useParams } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FileUpload from "../../../Common/FileUpload"; // Adjust the import based on your file structure

const UpdateBlog: React.FC = () => {
    const authContext = useContext(AuthContext);
    const [loader, setLoader] = useState(false);
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();
    const { menuId, id } = useParams(); // Assuming 'id' is the blog ID from the URL

    const [blogTitle, setBlogTitle] = useState("");
    const [shortText, setShortText] = useState("");
    const [content, setContent] = useState("");
    const [featuredImage, setFeaturedImage] = useState("");
    const [tags, setTags] = useState("");
    const [author, setAuthor] = useState("");
    const [metaDescription, setMetaDescription] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [fileId, setFileId] = useState("");
    const [slug, setSlug] = useState("");
    const [isId, setIsid] = useState("");

    useEffect(() => {
        fetchBlogDetails();
        fetchCategories();
    }, []);

    const fetchBlogDetails = async () => {
        setLoader(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API}/pipeline`, {
                filter: {
                    filter: {
                        logic: "and",
                        offset: 0,
                        limit: 1,
                        filters: [
                            {
                                field: "uuid",
                                operator: "eq",
                                table: "Posts",
                                value: id
                            }
                        ]
                    }
                },
                menuId,
                action: "command",
                command: [
                    {
                        "agent": "postLists",
                        "appName": "fileSharing",
                        "folder": "Posts/Post"
                    }
                ]
            }, {
                headers: {
                    'x-access-token': authContext.token,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200 && response.data.status === "success") {
                const blog = response.data.data[0];
                setBlogTitle(blog.title || "");
                setShortText(blog.shortText || "");
                setContent(blog.content || "");
                setFeaturedImage(blog.featuredImage || "");
                setTags(blog.tags || "");
                setAuthor(blog.author || "");
                setMetaDescription(blog.metaDescription || "");
                setSelectedCategory(blog.categoryId || "");
                setSlug(blog.slug || "");
                setIsid(blog._id || "");
            } else {
                toast.error('Failed to load blog details!');
            }
        } catch (error: any) {
            toast.error('Failed to load blog details! ' + error.message);
        } finally {
            setLoader(false);
        }
    };

    const fetchCategories = async () => {
        setLoader(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API}/pipeline`, {
                filter: {
                    filter: {
                        logic: "and",
                        offset: 0,
                        limit: 10,
                        filters: [],
                        sort: [
                            {
                                field: "createdAt",
                                table: "Categories",
                                dir: "desc"
                            }
                        ]
                    }
                },
                menuId,
                action: "command",
                command: [
                    {
                        agent: "categoriesList",
                        appName: "fileSharing",
                        folder: "Posts/Categories"
                    }
                ]
            }, {
                headers: {
                    'x-access-token': authContext.token,
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200 && response.data.status === "success") {
                setCategories(response?.data?.data);
            } else {
                toast.error('Failed to load categories!');
            }
        } catch (error: any) {
            toast.error('Failed to load categories! ' + error.message);
        } finally {
            setLoader(false);
        }
    };

    const onFileUploadCompleteID = (id: string) => {
        setFileId(id);
    }

    const resetForm = () => {
        setBlogTitle("");
        setShortText("");
        setContent("");
        setFeaturedImage("");
        setTags("");
        setAuthor("");
        setMetaDescription("");
        setSelectedCategory("");
        setSlug("");
    };

    const isFormValid = () => {
        return blogTitle && content && author && selectedCategory;
    };

    const onFileUploadComplete = (url: any) => {
        setFeaturedImage(url);
    };

    const submitBlog = async () => {
        setLoader(true);

        const payload = {
            title: blogTitle,
            shortText,
            content,
            featuredImage: featuredImage,
            tags,
            author,
            slug,
            keywords: tags,
            metaDescription,
            category: selectedCategory,
            id: isId, // Add blog ID to payload for updating
            menuId,
            action: "command",
            command: [
                {
                    agent: "updatePost",
                    appName: "fileSharing",
                    folder: "Posts/Post"
                }
            ]
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API}/pipeline`, payload, {
                headers: {
                    'x-access-token': authContext.token,
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200 && response.data.status === "success") {
                toast.success(response.data.message || 'Blog updated successfully!');
                navigate(`/dashboard/posts/${menuId}`);
            } else {
                toast.error(response.data.message || 'Something went wrong!');
            }
        } catch (error: any) {
            toast.error('Something went wrong! ' + error.message);
        } finally {
            setLoader(false);
        }
    };

    return loader ? <Shimmer /> : (
        <div className="mb-9">
            <div className="row g-3 flex-between-end mb-5">
                <div className="col-auto">
                    <h2 className="mb-2">Update Blog</h2>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary mb-2 mb-sm-0" type="button" onClick={submitBlog} disabled={!isFormValid()}>Update</button>
                </div>
            </div>
            <div className="row g-5">
                <div className="col-12 col-xl-8">
                    <h4 className="mb-3 mt-3">Blog Title <span className="text-danger">*</span></h4>
                    <input
                        className="form-control mb-5"
                        type="text"
                        placeholder="Enter blog title..."
                        value={blogTitle}
                        onChange={(e) => setBlogTitle(e.target.value)}
                        aria-label="blogTitle"
                    />
                    <h4 className="mb-3 mt-3">Short Description <span className="text-danger">*</span></h4>
                    <input
                        className="form-control mb-5"
                        type="text"
                        placeholder="Enter short description..."
                        value={shortText}
                        onChange={(e) => setShortText(e.target.value)}
                        aria-label="shortText"
                    />
                    <h4 className="mb-3 mt-3">Slug <span className="text-danger">*</span></h4>
                    <input
                        className="form-control mb-5"
                        type="text"
                        placeholder="Enter slug name..."
                        value={slug}
                        onChange={(e) => setSlug(e.target.value)}
                        aria-label="slug"
                    />
                    <h4 className="mb-3 mt-3">Content <span className="text-danger">*</span></h4>
                    <CKEditor
                        editor={ClassicEditor}
                        data={content}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setContent(data);
                        }}
                    />
                </div>
                <div className="col-12 col-xl-4">
                    <h4 className="mb-3 mt-3">Featured Image <span className="text-danger">*</span></h4>
                    <FileUpload
                        menuId={menuId || ''}
                        token={authContext.token || ''}
                        onFileUploadComplete={onFileUploadComplete}
                        onFileUploadCompleteID={onFileUploadCompleteID}
                        docType={"featuredImage"}
                        initialFile={featuredImage} // Pre-fill the featured image if available
                    />
                    <h4 className="mb-3 mt-3">Category <span className="text-danger">*</span></h4>
                    <select
                        className="form-control mb-5"
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        aria-label="category"
                    >
                        <option value="">Select category...</option>
                        {categories.map((category: any) => (
                            <option key={category._id} value={category._id}>{category.name}</option>
                        ))}
                    </select>

                    <h4 className="mb-3 mt-3">Tags <span className="text-danger">*</span></h4>
                    <input
                        className="form-control mb-5"
                        type="text"
                        placeholder="Enter tags..."
                        value={tags}
                        onChange={(e) => setTags(e.target.value)}
                        aria-label="tags"
                    />
                    <h4 className="mb-3 mt-3">Author <span className="text-danger">*</span></h4>
                    <input
                        className="form-control mb-5"
                        type="text"
                        placeholder="Enter author name..."
                        value={author}
                        onChange={(e) => setAuthor(e.target.value)}
                        aria-label="author"
                    />
                    <h4 className="mb-3 mt-3">Meta Description <span className="text-danger">*</span> </h4>
                    <textarea
                        className="form-control mb-5"
                        rows={3}
                        placeholder="Enter meta description..."
                        value={metaDescription}
                        onChange={(e) => setMetaDescription(e.target.value)}
                        aria-label="metaDescription"
                    />
                </div>
            </div>
        </div>
    );
};

export default UpdateBlog;
