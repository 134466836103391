import axios from "axios";
import React, { useContext, useState } from "react";
import AuthContext from "../../../../authContext";
import { toast } from "react-toastify";
import Shimmer from "../../Shimmer";
import { useNavigate, useParams } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CreateCategory: React.FC = () => {
    const authContext = useContext(AuthContext);
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const { menuId } = useParams();

    const [categoryName, setCategoryName] = useState("");
    const [categoryDescription, setCategoryDescription] = useState("");

    const resetForm = () => {
        setCategoryName("");
        setCategoryDescription("");
    };

    const isFormValid = () => {
        return categoryName.trim() !== "" && categoryDescription.trim() !== "";
    };

    const submitCategory = async () => {
        if (!isFormValid()) return;

        setLoader(true);

        const payload = {
            name: categoryName,
            description: categoryDescription,
            menuId,
            action: "command",
            command: [
                {
                    agent: "createCategories",
                    appName: "fileSharing",
                    folder: "Posts/Categories"
                }
            ]
        };

        const config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API}/pipeline`,
            headers: {
                'x-access-token': authContext.token,
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(payload)
        };

        try {
            const response = await axios.request(config);
            if (response.status === 200) {
                if (response.data.status === "success") {
                    toast.success(response.data.message || 'Category created successfully!');
                    resetForm();
                    navigate(`/dashboard/categories/${menuId}`); // Replace with the actual path to the category list page
                } else {
                    toast.error(response.data.message || 'Something went wrong!');
                }
            } else {
                toast.error(response.data.message || 'Something went wrong!');
            }
        } catch (error: any) {
            toast.error('Something went wrong! ' + error.message);
        } finally {
            setLoader(false);
        }
    };

    return loader ? <Shimmer /> : (
        <div className="mb-9">
            <div className="row g-3 flex-between-end mb-5">
                <div className="col-auto">
                    <h2 className="mb-2">Create Category</h2>
                </div>
                <div className="col-auto">
                    <button 
                        className="btn btn-primary mb-2 mb-sm-0" 
                        type="button" 
                        onClick={submitCategory} 
                        disabled={!isFormValid()}
                    >
                        Create
                    </button>
                </div>
            </div>
            <div className="row g-5">
                <div className="col-12 col-xl-8">
                    <h4 className="mb-3">Category Name <span className="text-danger">*</span></h4>
                    <input
                        className="form-control mb-5"
                        type="text"
                        placeholder="Enter category name..."
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                        aria-label="categoryName"
                    />
                    <h4 className="mb-3">Category Description <span className="text-danger">*</span></h4>
                    <CKEditor
                        editor={ClassicEditor}
                        data={categoryDescription}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setCategoryDescription(data);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default CreateCategory;
