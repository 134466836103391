import { Dropzone, FileMosaic } from "@files-ui/react";
import * as React from "react";
import axios from "axios";
import FormData from "form-data";

interface FileUploadProps {
    menuId: string;
    token: string;
    onFileUploadComplete: (url: any) => void;
    onFileUploadCompleteID: (id: string) => void;
    docType: string;
    initialFile?: string; // Optional prop for initial file URL
}

const FileUpload: React.FC<FileUploadProps> = ({ menuId, token, onFileUploadComplete, onFileUploadCompleteID, docType, initialFile }) => {
    const [files, setFiles] = React.useState<any[]>([]);
    const [uploadedFiles, setUploadedFiles] = React.useState<any[]>([]);

    React.useEffect(() => {
        if (initialFile) {
            setFiles([{ id: 'initial-file', file: { name: 'Initial Featured Image', size: 0 }, preview: initialFile }]);
        }
    }, [initialFile]);

    const validateFile = (file: any) => {
        const fileSizeLimit = 28 * 1024 * 1024; // 28 MB
        return file.file.size <= fileSizeLimit;
    };

    const uploadFile = async (file: any) => {
        let data = new FormData();
        data.append('file', file.file);
        data.append('action', 'formcommand');
        data.append('command', '[ { "agent": "FileUpload", "appName": "fileSharing", "folder": "documentManagment" } ]');
        data.append('docType', docType);
        data.append('menuId', menuId);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API}/pipeline`,
            headers: {
                'x-access-token': token
            },
            data: data
        };

        try {
            const response = await axios.request(config);
            if (response?.data?.statusCode === 200) {
                const fileData = response?.data?.data;
                onFileUploadComplete(fileData.url);
                onFileUploadCompleteID(fileData.id);
                setUploadedFiles((prev) => [...prev, fileData]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updateFiles = (incomingFiles: any[]) => {
        const validFiles = incomingFiles.filter(validateFile);
        setFiles(validFiles);
        validFiles.forEach(uploadFile);
    };

    const removeFile = (id: string) => {
        setFiles(files.filter((file) => file.id !== id));
        setUploadedFiles(uploadedFiles.filter((file: any) => file.id !== id));
    };

    return (
        <div>
            <Dropzone
                onChange={updateFiles}
                value={files}
                accept="image/*" // Accept any image files
                maxFileSize={28 * 1024 * 1024} // 28 MB
                maxFiles={1}
            >
                {files.map((file) => (
                    <FileMosaic
                        key={file.id}
                        {...file}
                        onDelete={() => removeFile(file.id)}
                        info
                        preview
                    />
                ))}
            </Dropzone>
        </div>
    );
}

export default FileUpload;
