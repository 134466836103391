import { Link, To } from "react-router-dom";
import React, { ChangeEvent, useContext } from "react";
import AuthContext from "../../authContext";

interface TableProps {
    columns: string[];
    data: any[];
    dataKey: string[];
    actionKey: string;
    onPageSizeChange: (size: number) => void;
    onPageChange: (page: number) => void;
    onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFilterChange: (filters: any) => void;
    onChangeOrder: (order: string) => void;
    onFilterColoum: (filterColoum: any) => void;
    page: number;
    pageSize: number;
    searchTerm: string;
    totalCount: number;
    totalPages: number;
    buttonsData: any[];
    initColStructure: any[];

}

const Table: React.FC<TableProps> = ({
    columns,
    data,
    dataKey,
    actionKey,
    onPageSizeChange,
    onPageChange,
    onSearchChange,
    onChangeOrder,
    onFilterColoum,
    page,
    pageSize,
    searchTerm,
    totalCount,
    totalPages,
    buttonsData,
    initColStructure
}) => {
    const authContext = useContext(AuthContext);
    console.log("buttonsData", buttonsData);
    const CustomButton = ({ data }: { data: { name: string, key: string, read: boolean, write: boolean, action: string, icon: string, style?: React.CSSProperties, link: string, onClick?: () => void, tooltip?: string, disabled?: boolean, type?: "button" | "submit" | "reset" } }) => {
        const { name, key, read, write, action, icon, style, link, onClick, tooltip, type } = data;
        return (
            <>
                {read === true && action === "read" ? (
                    <Link to={`${link}`} className="btn btn-primary" id={`${key}Btn`} style={style} onClick={onClick} title={tooltip} type={type}>
                        <span className={icon}></span>{name}
                    </Link>
                ) : write === true && action === "write" ? (
                    <Link to={`${link}`} className="btn btn-primary" id={`${key}Btn`} style={style} onClick={onClick} title={tooltip} type={type}>
                        <span className={icon}></span>{name}
                    </Link>
                ) : null}
            </>
        );
    };
    const getBadgeClass = (value: any) => {
        switch (value) {
            case 0:
            case false:
                return 'warning'; // Example classes for value 0
            case 1:
            case true:
                return 'success'; // Example classes for value 1
            case 2:
                return 'danger'; // Example classes for value 2

        }
    }
    const getBadgeName = (value: any) => {
        switch (value) {
            case 0:
            case false:
                return 'Pending'; // Example classes for value 0
            case 1:
            case true:
                return 'Published'; // Example classes for value 1
            case 2:
                return 'deleted'; // Example classes for value 2
        }
    }
    const UserTableRow = ({ user }: { user: any }) => {
        return (
            <tr className="position-static" key={user[actionKey]}>
                <td className="fs-9 align-middle">
                    <div className="form-check mb-0 fs-8">#</div>
                </td>
                {initColStructure.map((column: any, index) => {
                    if (column.type === "tags") {

                        return (
                            <td key={index} className="align-middle white-space-nowrap py-0">
                                <span className="display-flex align-items-center">
                                    {user[column.key].split(",").map((tag: any, i: number) => {
                                        if (i > 2)
                                            return null;
                                        return (
                                            <span className={'badge badge-phoenix fs-10 badge-phoenix-secondary'} style={{ margin: "2px" }} key={i}>
                                                <span className="badge-label">{tag}</span>
                                            </span>
                                        );
                                    })}
                                </span>
                            </td>
                        );
                    } else if (column.type === "badges") {
                        let badgeClass = getBadgeClass(user[column.key]);
                        let badgeName = getBadgeName(user[column.key]);
                        return (
                            <td key={index} className="align-middle white-space-nowrap py-0">
                                <span className={'badge badge-phoenix fs-10 badge-phoenix-' + badgeClass}>
                                    <span className="badge-label">{badgeName}</span>

                                </span>
                            </td>
                        );
                    } else if (column.type === "phone") {
                        return (
                            <td key={index} className="align-middle white-space-nowrap py-0">
                                <Link to={`tel:${user[column.key]}`} target={user[column.linkTarget] ?? "_blank"}>{user[column.key]}</Link>
                            </td>
                        );
                    } else if (column.type === "link") {
                        return (
                            <td key={index} className="align-middle white-space-nowrap py-0">
                                <Link to={user[column.key]} target={user[column.linkTarget] ?? "_blank"}>{user[column.key]}</Link>
                            </td>
                        );
                    } else if (column.type === "text") {
                        return (
                            <td key={index} className="align-middle white-space-nowrap py-0">
                                <p style={{ "textWrap": "wrap" }}>{user[column.key]}</p>
                            </td>
                        );
                    } else if (column.type === "image") {
                        return (
                            <td key={index} className="align-middle white-space-nowrap py-0">
                                <Link className="d-block border border-translucent rounded-2" to="#">
                                    <img src={user[column.key] ?? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4YreOWfDX3kK-QLAbAL4ufCPc84ol2MA8Xg&s"} alt="Profile" width="53" />
                                </Link>
                            </td>
                        );
                    } else if (column.type === "pdf") {
                        return (
                            <td key={index} className="align-middle white-space-nowrap py-0">
                                <Link className="d-block border border-translucent rounded-2" to="#">
                                    <img src={"https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1667px-PDF_file_icon.svg.png"} alt="Profile" width="53" />
                                </Link>
                            </td>
                        );
                    } else if (column.type === "date") {
                        return (
                            <td key={index} className="align-middle white-space-nowrap text-body-tertiary text-opacity-85 ps-4">
                                {new Date(user[column.key]).toLocaleString()}
                            </td>
                        );
                    } else if (column.type === "action") {
                        return (
                           
                            <td key={index} className="align-middle white-space-nowrap text-end pe-0 ps-4 btn-reveal-trigger">
                                <div className="btn-reveal-trigger position-static">
                                    <button
                                        className="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs-10"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        data-boundary="window"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        data-bs-reference="parent"
                                    >
                                        <span className="fas fa-ellipsis-h fs-10"></span>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-end py-2">
                                    {console.log(user[column.id])}
                                        {column.action
                                            .map((action: {
                                                read: boolean;
                                                action: string;
                                                write: boolean; link: To; onClick: React.MouseEventHandler<HTMLAnchorElement> | undefined; icon: string | undefined; name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined;
                                            }, actionIndex: React.Key | null | undefined) => (
                                                action?.read === true && action?.action === "read" ? (
                                                    <Link to={action.link + '/' + user[column.id]} className="dropdown-item" key={actionIndex} onClick={() => action.onClick?.(user)}>
                                                        <span className={action.icon}></span>{action.name}
                                                    </Link>
                                                ) : action?.write === true && action?.action === "write" ? (
                                                    column.id === "DocumentLocalURL" ? (
                                                        <a href={action.link + '/' + user[column.id]} target="_blank" className="dropdown-item" key={actionIndex}>
                                                            <span className={action.icon}></span>{action.name}
                                                        </a>
                                                    ) : (
                                                        <Link to={action.link + '/' + user[column.id]} className="dropdown-item" key={actionIndex} onClick={() => action.onClick?.(user)}>
                                                            <span className={action.icon}></span>{action.name}
                                                        </Link>
                                                    )
                                                ) : null
                                            ))
                                        }
                                    </div>
                                </div>
                            </td>
                        );
                    } else {
                        return (
                            <td key={index} className="align-middle white-space-nowrap py-0">
                                {user[column.key]}
                            </td>
                        );
                    }
                })}
            </tr>
        );
    };
    return (
        <>
            <ul className="nav nav-links mb-3 mb-lg-2 mx-n3">
                <li className="nav-item"><a className="nav-link active" aria-current="page" href="#"><span>All </span><span className="text-body-tertiary fw-semibold">({totalCount})</span></a></li>
            </ul>
            <div id="products" data-list={`{"valueNames":${actionKey},"page":${pageSize},"pagination":true}`}>
                <div className="mb-4">
                    <div className="d-flex flex-wrap gap-3">
                        <div className="search-box">
                            <form className="position-relative">
                                <input
                                    className="form-control search-input search"
                                    type="search"
                                    placeholder="Search document"
                                    aria-label="Search"
                                    value={searchTerm}
                                    onChange={onSearchChange}
                                />
                                <span className="fas fa-search search-box-icon"></span>
                            </form>
                        </div>
                        <div className="scrollbar overflow-hidden-y">
                            <div className="btn-group position-static" role="group">
                                <div className="btn-group position-static text-nowrap">
                                    <button className="btn btn-phoenix-secondary px-7 flex-shrink-0" type="button" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"> Column<span className="fas fa-angle-down ms-2"></span></button>
                                    <ul className="dropdown-menu">
                                        {initColStructure.map((column, index) => (
                                            <li key={index} onClick={() => onFilterColoum(column.label)}>
                                                {column.isSearchable &&
                                                    (<Link className="dropdown-item" to="#!">{column.label}</Link>)}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="btn-group position-static text-nowrap">
                                    <button className="btn btn-sm btn-phoenix-secondary px-7 flex-shrink-0" type="button" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"> Sort<span className="fas fa-angle-down ms-2"></span></button>
                                    <ul className="dropdown-menu">
                                        <li onClick={() => onChangeOrder("asc")}><Link to={"#!"} className="dropdown-item">ASC</Link></li>
                                        <li onClick={() => onChangeOrder("desc")}><Link to={"#!"} className="dropdown-item">DESC</Link></li>
                                    </ul>
                                </div>
                                <div className="btn-group position-static text-nowrap">
                                    <button className="btn btn-sm btn-phoenix-secondary px-7 flex-shrink-0" type="button" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"> No of rows<span className="fas fa-angle-down ms-2"></span></button>
                                    <ul className="dropdown-menu">
                                        <li onClick={() => onPageSizeChange(10)}><Link to={"#!"} className="dropdown-item">10</Link></li>
                                        <li onClick={() => onPageSizeChange(25)}><Link to={"#!"} className="dropdown-item">25</Link></li>
                                        <li onClick={() => onPageSizeChange(50)}><Link to={"#!"} className="dropdown-item">50</Link></li>
                                        <li onClick={() => onPageSizeChange(100)}><Link to={"#!"} className="dropdown-item">100</Link></li>
                                        <li onClick={(u) => onPageSizeChange(250)}><Link to={"#!"} className="dropdown-item">250</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="ms-xxl-auto">
                            {buttonsData.map((button, index) => (
                                <CustomButton key={index} data={button} />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
                    <div className="table-responsive scrollbar mx-n1 px-1">
                        <table className="table fs-9 mb-0">
                            <thead>
                                <tr>
                                    <th className="white-space-nowrap fs-9 align-middle ps-0" style={{ maxWidth: "20px", width: "18px" }}>
                                        <div className="form-check mb-0 fs-8">
                                            #
                                        </div>
                                    </th>
                                    {initColStructure.map((column, index) => (
                                        (column?.label !== "Actions") &&
                                        <th key={index} className="sort white-space-nowrap align-middle fs-10" scope="col" style={{ width: "70px" }} data-sort={column}>
                                            {column?.label}
                                        </th>
                                    ))}

                                </tr>
                            </thead>
                            <tbody className="list" id="products-table-body">
                                {data && data.length > 0 ? data.map((user, index) => (
                                    <UserTableRow key={index} user={user} />

                                )) : (
                                    <tr>
                                        <td colSpan={9} className="text-center">
                                            <p>No data found</p>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="row align-items-center justify-content-between py-2 pe-0 fs-9">
                        <div className="col-auto d-flex">
                            <p className="mb-0 d-none d-sm-block me-3 fw-semibold text-body" data-list-info="data-list-info">Page {page} of {totalPages}</p>
                        </div>
                        <div className="col-auto d-flex">
                            <button className="page-link" onClick={() => onPageChange(page - 1)} disabled={page <= 1}><span className="fas fa-chevron-left"></span></button>
                            <ul className="mb-0 pagination">
                                {totalPages && [...Array(totalPages)].map((_, pageIndex) => (
                                    <li key={pageIndex} className={`page-item ${pageIndex + 1 === page ? 'active' : ''}`}>
                                        <a className="page-link" href="#" onClick={(e) => { e.preventDefault(); onPageChange(pageIndex + 1); }}>{pageIndex + 1}</a>
                                    </li>
                                ))}
                            </ul>
                            <button className="page-link pe-0totalPages" onClick={() => onPageChange(page + 1)} disabled={page >= totalPages}><span className="fas fa-chevron-right"></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Table;
