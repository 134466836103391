import React from 'react';

const Shimmer = () => {
  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px', background: '', borderRadius: '8px' }}>
        <div style={{ width: '30%', height: '20px', background: 'rgba(var(--phoenix-emphasis-color-rgb), 0.1)', borderRadius: '4px', animation: 'shimmer-animation 1.5s infinite alternate' }}></div>
        <div style={{ display: 'flex', gap: '10px' }}>
          <div style={{ width: '120px', height: '30px', background: 'rgba(var(--phoenix-emphasis-color-rgb), 0.1)', borderRadius: '4px', animation: 'shimmer-animation 1.5s infinite alternate' }}></div>
          <div style={{ width: '120px', height: '30px', background: 'rgba(var(--phoenix-emphasis-color-rgb), 0.1)', borderRadius: '4px', animation: 'shimmer-animation 1.5s infinite alternate' }}></div>
        </div>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '20px', background: '', borderRadius: '8px', marginBottom: '20px' }}>
        <div style={{ width: '60%', height: '20px', background: 'rgba(var(--phoenix-emphasis-color-rgb), 0.1)', borderRadius: '4px', animation: 'shimmer-animation 1.5s infinite alternate' }}></div>
        <div style={{ width: '20%', height: '20px', background: 'rgba(var(--phoenix-emphasis-color-rgb), 0.1)', borderRadius: '4px', animation: 'shimmer-animation 1.5s infinite alternate' }}></div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '20px', background: '', borderRadius: '8px' }}>
        {[...Array(3)].map((_, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '20px', background: '', borderRadius: '8px' }}>
            <div style={{ width: '40px', height: '40px', borderRadius: '4px', background: 'rgba(var(--phoenix-emphasis-color-rgb), 0.1)', animation: 'shimmer-animation 1.5s infinite alternate' }}></div>
            <div style={{ flex: 1, marginLeft: '10px' }}>
              <div style={{ marginBottom: '8px', width: '70%', height: '20px', background: 'rgba(var(--phoenix-emphasis-color-rgb), 0.1)', borderRadius: '4px', animation: 'shimmer-animation 1.5s infinite alternate' }}></div>
              <div style={{ width: '50%', height: '20px', background: 'rgba(var(--phoenix-emphasis-color-rgb), 0.1)', borderRadius: '4px', animation: 'shimmer-animation 1.5s infinite alternate' }}></div>
            </div>
            <div style={{ width: '150px', height: '20px', background: 'rgba(var(--phoenix-emphasis-color-rgb), 0.1)', borderRadius: '4px', animation: 'shimmer-animation 1.5s infinite alternate' }}></div>
          </div>
        ))}
      </div>

      <style>
        {`
          @keyframes shimmer-animation {
            0% {
              background-position: -200%;
            }
            100% {
              background-position: 200%;
            }
          }
        `}
      </style>
    </div>
  );
}

export default Shimmer;
