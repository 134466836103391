import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../../../authContext";
import { toast } from "react-toastify";
import Shimmer from "../../Shimmer";
import { useNavigate, useParams } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FileUpload from "../../../Common/FileUpload"; // Adjust the import based on your file structure

const CreateCaseStudy: React.FC = () => {
    const authContext = useContext(AuthContext);
    const [loader, setLoader] = useState(false);
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();
    const { menuId } = useParams();

    const [blogTitle, setBlogTitle] = useState("");
    const [shortText, setShortText] = useState("");
    const [content, setContent] = useState("");
    const [featuredImage, setFeaturedImage] = useState("");
    const [tags, setTags] = useState("");
    const [author, setAuthor] = useState("");
    const [metaDescription, setMetaDescription] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [fileId, setFileId] = useState("");
    const [slug, setSlug] = useState("");
    

    const onFileUploadCompleteID = (id: string) => {
        setFileId(id);
    }


    const resetForm = () => {
        setBlogTitle("");
        setShortText("");
        setContent("");
        setFeaturedImage("");
        setTags("");
        setAuthor("");
        setMetaDescription("");
        setSelectedCategory("");
        setSlug("");
    };

    const isFormValid = () => {
        return blogTitle && content && slug;
    };

    const onFileUploadComplete = (url: any) => {
        setFeaturedImage(url?.url);
    };

    const submitBlog = async () => {
        setLoader(true);

        const payload = {
            title: blogTitle,
            shortText,
            content,
            featuredImage,
            slug,
            keywords: tags,
            metaDescription,
            isPublish: true, // Add this if the blog should be published immediately
            menuId,
            action: "command",
            command: [
                {
                    agent: "createCaseStudy",
                    appName: "fileSharing",
                    folder: "Posts/Case Study"
                }
            ]
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API}/pipeline`, payload, {
                headers: {
                    'x-access-token': authContext.token,
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200 && response.data.status === "success") {
                toast.success(response.data.message || 'Case study created successfully!');
                resetForm();
                navigate(`/dashboard/casestudy/${menuId}`);
            } else {
                toast.error(response.data.message || 'Something went wrong!');
            }
        } catch (error: any) {
            toast.error('Something went wrong! ' + error.message);
        } finally {
            setLoader(false);
        }
    };

    return loader ? <Shimmer /> : (
        <div className="mb-9">
            <div className="row g-3 flex-between-end mb-5">
                <div className="col-auto">
                    <h2 className="mb-2">Create Case Studies</h2>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary mb-2 mb-sm-0" type="button" onClick={submitBlog} disabled={!isFormValid()}>Create</button>
                </div>
            </div>
            <div className="row g-5">
                <div className="col-12 col-xl-8">
                    <h4 className="mb-3 mt-3">Case Studies Title <span className="text-danger">*</span></h4>
                    <input
                        className="form-control mb-5"
                        type="text"
                        placeholder="Enter case study title..."
                        value={blogTitle}
                        onChange={(e) => setBlogTitle(e.target.value)}
                        aria-label="caseStudyTitle"
                    />
                    <h4 className="mb-3 mt-3">Short Description <span className="text-danger">*</span></h4>
                    <input
                        className="form-control mb-5"
                        type="text"
                        placeholder="Enter short description..."
                        value={shortText}
                        onChange={(e) => setShortText(e.target.value)}
                        aria-label="shortText"
                    />
                    <h4 className="mb-3 mt-3">Slug <span className="text-danger">*</span></h4>
                    <input
                        className="form-control mb-5"
                        type="text"
                        placeholder="Enter slug name..."
                        value={slug}
                        onChange={(e) => setSlug(e.target.value)}
                        aria-label="slug"
                    />
                    <h4 className="mb-3 mt-3">Content <span className="text-danger">*</span></h4>
                    <CKEditor
                        editor={ClassicEditor}
                        data={content}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setContent(data);
                        }}
                    />
                </div>
                <div className="col-12 col-xl-4">
                    <h4 className="mb-3 mt-3">Featured Image <span className="text-danger">*</span></h4>
                    <FileUpload 
                        menuId={menuId || ''} 
                        token={authContext.token || ''} 
                        onFileUploadComplete={onFileUploadComplete} 
                        onFileUploadCompleteID={onFileUploadCompleteID} 
                        docType={"featuredImage"} 
                    />
                    {/* <h4 className="mb-3 mt-3">Category <span className="text-danger">*</span></h4>
                    <select
                        className="form-control mb-5"
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        aria-label="category"
                    >
                        <option value="">Select category...</option>
                        {categories.map((category: any) => (
                            <option key={category.id} value={category.id}>{category.name}</option>
                        ))}
                    </select> */}

                    {/* <h4 className="mb-3 mt-3">Tags <span className="text-danger">*</span></h4>
                    <input
                        className="form-control mb-5"
                        type="text"
                        placeholder="Enter tags..."
                        value={tags}
                        onChange={(e) => setTags(e.target.value)}
                        aria-label="tags"
                    />
                    <h4 className="mb-3 mt-3">Author <span className="text-danger">*</span></h4>
                    <input
                        className="form-control mb-5"
                        type="text"
                        placeholder="Enter author name..."
                        value={author}
                        onChange={(e) => setAuthor(e.target.value)}
                        aria-label="author"
                    /> */}
                    <h4 className="mb-3 mt-3">Meta Description <span className="text-danger">*</span> </h4>
                    <textarea
                        className="form-control mb-5"
                        rows={3}
                        placeholder="Enter meta description..."
                        value={metaDescription}
                        onChange={(e) => setMetaDescription(e.target.value)}
                        aria-label="metaDescription"
                    />
                </div>
            </div>
        </div>
    );
};

export default CreateCaseStudy;
