import React, { createContext, useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import axios from 'axios';

interface AuthContextProps {
    token: string | null;
    role: string | null;
    refreshToken: string | null;
    isLoggedIn: boolean;
    login: (userCredentials: UserCredentials, role: RoleData) => void;
    logout: () => void;
    currency: string;
    code: string;
    convertCurrency: (number: number) => string;
    userMenu: any;
    userInfo: any;
    organization: any;
}

interface UserCredentials {
    token: string;
    refreshToken: string;
    code: string;
    currencyCode: string;
}


interface RoleData {
    role: string;
}

const AuthContext = createContext<AuthContextProps>({
    token: '',
    role: '',
    refreshToken: '',
    isLoggedIn: false,
    login: (token, role) => { },
    logout: () => { },
    currency: 'USD',
    code: 'en-US',
    convertCurrency: (number) => '',
    userMenu: [],
    userInfo: [],
    organization: []
});

export const AuthContextProvider = (props: any) => {
    const initToken = localStorage.getItem('token');
    const initRole = localStorage.getItem('userRole');
    const initrefreshToken = localStorage.getItem('refreshToken');
    const codes = localStorage.getItem('code');
    const currencyCode = localStorage.getItem('currencyCode');
    const userMenus = localStorage.getItem('userMenu');
    const userInfos = localStorage.getItem('userInfo');
    const organizations = localStorage.getItem('organization');
    const [token, setToken] = useState<string | null>(initToken);
    const [userRole, setRole] = useState<string | null>(initRole);
    const [refreshToken, setrefreshToken] = useState<string | null>(initrefreshToken);
    const [currency, setCurrency] = useState<string>(currencyCode || 'USD');
    const [code, setCode] = useState<string>(codes || 'en-US');
    const [userMenu, setUserMenu] = useState(userMenus);
    const [userInfo, setUserInfo] = useState(userInfos);
    const [organization, setOrganization] = useState(organizations);
    const [refreshToken2, setRefreshToken2] = useState<any>('');
    const userIsLoggedIN = !!token;

    useEffect(() => {
        if(token){
            const interval = setInterval(() => {
                const refeshToken =  async () => {
                    const result = await axios.post(`${process.env.REACT_APP_API}/pipeline`,{
                        "action": "command",
                        "command": [
                            {
                                "agent": "refreshToken",
                                "appName": "fileSharing",
                                "folder": "auth"
                            }
                        ]
                    }, {
                        headers : {
                            'x-access-refreshToken' :refreshToken
                        }
                    })
    
                    setRefreshToken2(result?.data);
                    logginHandler(result?.data?.data, result?.data?.data?.role)
                   
            
                };
                refeshToken()
            },  3.5 * 60 * 1000); // 5 minutes in milliseconds
        
            // Cleanup function to clear the interval when the component unmounts or when the token changes
            return () => clearInterval(interval);
        }
    }, [token, refreshToken])
    
    function logginHandler (userCredentials: any, roleData: any)  {
        console.log("TokenData", userCredentials)
        setToken(userCredentials?.autTokens?.token);
        setrefreshToken(userCredentials?.autTokens?.refreshToken);
        setCode("1");
        setRole(roleData);
        setCurrency(userCredentials?.userData?.organization[0]?.currencyCode);
        setUserMenu(userCredentials?.userData?.userMenus);
        setUserInfo(userCredentials?.userData?.userInfo);
        setOrganization(userCredentials?.userData?.organization);

        const secretKey = '98760';
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(roleData), secretKey).toString();

        localStorage.setItem('code', userCredentials?.userData?.organization[0]?.code);
        localStorage.setItem('userRole', encryptedData);
        localStorage.setItem('currencyCode', userCredentials?.userData?.organization[0]?.currencyCode);
        localStorage.setItem('token', userCredentials?.autTokens?.token);
        localStorage.setItem('refreshToken', userCredentials?.autTokens?.refreshToken);
        localStorage.setItem('userMenu', JSON.stringify(userCredentials?.userData?.userMenus, null, 4));
        localStorage.setItem('userInfo', JSON.stringify(userCredentials?.userData?.userInfo, null, 4));
        localStorage.setItem('organization', JSON.stringify(userCredentials?.userData?.organization, null, 4));
      
    };

    const logoutHandler = () => {
        setToken(null);
        setrefreshToken(null);
        localStorage.clear();
        localStorage.clear();
    };

    const handleCurrency = (number: number): string => {
        const formatter = new Intl.NumberFormat(code, {
            style: 'currency',
            currency: currency,
        });
        return formatter.format(number);
    };

    const contextValue: AuthContextProps = {
        token: token,
        role: userRole,
        refreshToken: refreshToken,
        isLoggedIn: userIsLoggedIN,
        login: logginHandler,
        logout: logoutHandler,
        currency: currency,
        code: code,
        convertCurrency: handleCurrency,
        userMenu: userMenu,
        userInfo: userInfo,
        organization: organization
    };

    // const body = {
    //     "action": "command",
    //     "command": [
    //         {
    //             "agent": "refreshToken",
    //             "appName": "fileSharing",
    //             "folder": "auth"
    //         }
    //     ]
    // }
    // useEffect(() => {
    //     const interVal = setInterval(async (userRole : any) => {
    //         console.log(userRole, 'role data from useEffect')
    //         // setActive(false);
    //         try {

    //             const res: any = await fetch(`${process.env.REACT_APP_API}/pipeline`, {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'x-access-refreshToken': `${localStorage.getItem('refreshToken')}`,
    //                 },
    //                 body: JSON.stringify(body),
    //             }).then(response => {
    //                 if (!response.ok) {
    //                     throw new Error('Invalid credentials');
    //                 } else {
    //                     return response.json();
    //                 }
    //             });

    //             console.log(res)
    //             const tokenData = res?.data?.response?.data;

    //             setToken(tokenData?.autTokens?.token);
    //             setrefreshToken(tokenData?.autTokens?.refreshToken);
    //             setCode("1");
    //             setCurrency(tokenData?.userData?.organization[0]?.currencyCode);
    //             setUserMenu(tokenData?.userData?.userMenus);
    //             setOrganization(tokenData?.userData?.organization);
    //             localStorage.setItem('code', tokenData?.userData?.organization[0]?.code);
    //             localStorage.setItem('currencyCode', tokenData?.userData?.organization[0]?.currencyCode);
    //             localStorage.setItem('userRole', userRole);
    //             localStorage.setItem('token', tokenData?.autTokens?.token);
    //             localStorage.setItem('userMenu', JSON.stringify(tokenData?.userData?.userMenus, null, 4));
    //             localStorage.setItem('organization', JSON.stringify(tokenData?.userData?.organization, null, 4));

    //         } catch (error) {
    //             console.log("TokenData Error", error);
    //             localStorage.clear();
    //             setToken('');
    //             setrefreshToken('');
    //             setUserMenu('');
    //             setOrganization('');
    //             if (clearInt) {
    //                 clearInterval(interVal);
    //             }
    //             console.error(error);
    //         }
    //     }, 30000); // 1800000
    //     if (!userIsLoggedIN) {
    //         clearInterval(interVal);
    //     }

    //     return () => clearInterval(interVal);
    // }, [userIsLoggedIN]);

    return <AuthContext.Provider value={contextValue}>
        {props.children}
    </AuthContext.Provider>;
};

export default AuthContext;
