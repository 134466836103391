import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../../../authContext";
import { toast } from "react-toastify";
import Shimmer from "../../Shimmer";
import { Link, useParams } from "react-router-dom";
import Table from "../../../Common/Table";
import ShareDocumentModal from "../../../Common/ShareDocumentModal";
import DirectShareDocument from "../../../Common/DirectShareDocument";
import swal from "sweetalert";
const Blogs: React.FC = () => {
    const [loader, setLoader] = useState(true);
    const [categoryList, setCategoryList] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const authContext = useContext(AuthContext);
    const [totalCount, setTotalCount] = useState(0);
    const [columns, setColumns] = useState(["FEATURED", "TITLE", "DESCRIPTION", "AUTHOR", "CREATED"]);
    const [dataKey, setDataKey] = useState(["featuredImage", "title", "shortText", "author", "createdAt"]);
    const [actionKey, setActionKey] = useState("uuid");
    const [order, setTableOrder] = useState("desc");
    const [filtersData, setFilters] = useState<any>([]);
    const [filterColumn, setFilterColumn] = useState("name");
    const { menuId } = useParams();
    const debounceTimeoutRef = useRef<number | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [showModalDirectShare, setShowModalDirectShare] = useState(false);
    const [categoryId, setCategoryId] = useState("");
    const [categoryName, setCategoryName] = useState("");

    const handleShow = (catId: React.SetStateAction<any>) => {
        setCategoryId(catId?.uuid);
        setCategoryName(catId?.name);
        setShowModal(true);
    };
    const handleShowDirectShare = (cat: React.SetStateAction<any>) => {
        setCategoryId(cat?.uuid);
        setCategoryName(cat?.name);
        setShowModalDirectShare(true);
    };
    const handleClose = () => setShowModal(false);
    const handleCloseDirectShare = () => setShowModalDirectShare(false);
    const findMenuById = (menu: any, menuId: any) => {
        for (const item of menu) {
            if (item.menuId === menuId) {
                return { read: item.read ? true : false, write: item.write ? true : false };
            }
            if (item.subMenu && item.subMenu.length > 0) {
                const result: any = findMenuById(item.subMenu, menuId);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    }

    const handelDelete = (row: any) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Blog!",
            icon: "warning",
            buttons: ["Cancel", true],
            dangerMode: true,
        })
            .then(async (willDelete: any) => {
                if (willDelete) {
                    // Add your delete logic here
                    let data = JSON.stringify({
                        "id": `${row?._id}`,
                        "menuId": menuId,
                        "action": "command",
                        "command": [
                            {
                                "agent": "deletePost",
                                "appName": "fileSharing",
                                "folder": "Posts/Post"
                            }
                        ]
                    });

                    let config = {
                        method: 'POST',
                        maxBodyLength: Infinity,
                        url: `${process.env.REACT_APP_API}/pipeline`,
                        headers: {
                            'x-access-token': authContext.token,
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };

                    try {
                        const response = await axios.request(config);
                        if (response.status === 200) {
                            console.log(`Blog with id ${row?.id} deleted`);
                            swal("Poof! Your Blog has been deleted!", {
                                icon: "success",
                            });
                            fetchData();
                        }
                    } catch (error) {
                        console.error(error);
                    } finally {
                        setLoader(false);
                    }

                } else {
                    swal("Your user is safe!");
                }
            });
    }
    const initialMapping = {
        "NAME": "name",
        "DESCRIPTION": "description",
        "CREATED": "createdAt"
    };


    const initColStructure = [
        {
            "label": "FEATURED",
            "type": "image",
            "key": "featuredImage",
            "link": false,
            "isSearchable": false
        },
        {
            "label": "TITLE",
            "type": "text",
            "key": "title",
            "link": false,
            "isSearchable": true
        },
        // {
        //     "label": "DESCRIPTION",
        //     "type": "text",
        //     "key": "shortText",
        //     "link": false,
        //     "isSearchable": true
        // },
        {
            "label": "AUTHOR",
            "type": "text",
            "key": "author",
            "link": false,
            "isSearchable": true
        },
        {
            "label": "CREATED",
            "type": "date",
            "key": "createdAt",
            "link": false,
            "isSearchable": false
        },
        {
            "label": "ACTION",
            "type": "action",
            "key": "uuid",
            "link": true,
            "id": "uuid",
            "isSearchable": false,
            "action": [
                {
                    "name": "Edit",
                    "link": "blog",
                    ...(findMenuById(JSON.parse(localStorage.getItem("userMenu") || "[]"), menuId) || {}),
                    "action": "write",
                    "icon": "fas fa-edit me-2",
                    "style": {
                        "color": "green",
                        "borderColor": "green",
                        "backgroundColor": "transparent"
                    },
                    "onClick": (id: any) => {
                        // handleDelete(id);
                    },
                    "tooltip": "Edit Case Study",
                    "type": "button"
                },
                {
                    "name": "Delete",
                    "link": "#!",
                    ...(findMenuById(JSON.parse(localStorage.getItem("userMenu") || "[]"), menuId) || {}),
                    "action": "write",
                    "icon": "fas fa-trash me-2",
                    "style": {
                        "color": "red",
                        "borderColor": "red",
                        "backgroundColor": "transparent"
                    },
                    "onClick": (id: any) => {
                        console.log("Delete Case Study " + id);
                        handelDelete(id);
                    },
                    "tooltip": "Delete Case Study",
                    "type": "button"
                }
            ]
        }
    ];

    const [actionButton, setActionButton] = useState([{
        "name": "Create Blog",
        "key": "createBlog",
        ...(findMenuById(JSON.parse(localStorage.getItem("userMenu") || "[]"), menuId) || {}),
        "action": "write",
        "icon": "fas fa-plus me-2",
        "style": {
            "color": "green",
            "borderColor": "green",
            "backgroundColor": "transparent"
        },
        "link": "create-blog",
        "onClick": () => {
        },
        "tooltip": "Create Blog",
        "disabled": false,
        "type": "button",

    }]);
    const fetchData = async (filters = {}) => {
        setLoader(true);
        let data = JSON.stringify({
            "filter": {
                "filter": {
                    "logic": "and",
                    "offset": (page - 1) * pageSize,
                    "limit": pageSize,
                    "filters": [...filtersData],
                    "sort": [
                        {
                            "field": "createdAt",
                            "table": "Categories",
                            "dir": order
                        }
                    ]
                }
            }, "menuId": menuId,
            "action": "command",
            "command": [
                {
                    "agent": "postLists",
                    "appName": "fileSharing",
                    "folder": "Posts/Post"
                }
            ]
        });

        let config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API}/pipeline`,
            headers: {
                'x-access-token': authContext.token,
                'Content-Type': 'application/json'
            },
            data: data
        };

        try {
            const response = await axios.request(config);
            if (response.status === 200) {
                setCategoryList(response?.data?.data);
                setTotalPages(Math.ceil(response?.data?.totalCount / pageSize));
                setTotalCount(response?.data?.totalCount);
            } else {
                setCategoryList([]);
                setTotalPages(0);
                setTotalCount(0);
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to fetch categories");
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, pageSize, filtersData, order]);

    useEffect(() => {
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }
        debounceTimeoutRef.current = window.setTimeout(() => {
            if (initialMapping[filterColumn as keyof typeof initialMapping]) {
                setFilters([{
                    field: initialMapping[filterColumn as keyof typeof initialMapping] ?? "name",
                    operator: "contain",
                    table: "Categories",
                    value: searchTerm
                }]);
            }
        }, 1000); // 1000ms debounce time
    }, [searchTerm, filterColumn]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };

    const handlePageSizeChange = (size: number) => {
        setPageSize(size);
        setPage(1); // reset to first page
    };

    const handleTableOrderChange = (order: string) => {
        setTableOrder(order);
    };

    const handleFilterChange = (filters: any) => {
        setFilters(filters);
    };

    const onFilterColumn = (filterColumn: any) => {
        setFilterColumn(filterColumn);
    };

    return loader ? <Shimmer /> : (
        <>
            <div className="mb-9">
                <div className="row g-3 mb-4">
                    <div className="col-auto">
                        <h2 className="mb-0">Blog Lists</h2>
                    </div>
                </div>
                <Table
                    columns={columns}
                    data={categoryList}
                    dataKey={dataKey}
                    actionKey={actionKey}
                    onPageSizeChange={handlePageSizeChange}
                    onPageChange={handlePageChange}
                    onSearchChange={handleSearchChange}
                    onFilterChange={handleFilterChange}
                    onChangeOrder={handleTableOrderChange}
                    onFilterColoum={onFilterColumn}  // Ensure consistency with the component's props
                    page={page}
                    pageSize={pageSize}
                    searchTerm={searchTerm}
                    totalCount={totalCount}
                    totalPages={totalPages}
                    buttonsData={actionButton}
                    initColStructure={initColStructure}
                />
            </div>

        </>
    );
};

export default Blogs;