import React, { useContext, useEffect, lazy, Suspense } from 'react';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import AuthContext from './authContext';
import AuthChecker from './AuthChecker';
import Categories from './pages/AdminDashboard/Menus/categories/Categories';
import CreateCategory from './pages/AdminDashboard/Menus/categories/CreateCategory';
import Blogs from './pages/AdminDashboard/Menus/Blog/Blogs';
import CreateBlog from './pages/AdminDashboard/Menus/Blog/CreateBlog';
import CrateCaseStudy from './pages/AdminDashboard/Menus/CaseStudy/CrateCaseStudy';
import CaseStudy from './pages/AdminDashboard/Menus/CaseStudy/CaseStudy';
import UpdateCaseStudy from './pages/AdminDashboard/Menus/CaseStudy/UpdateCaseStudy';
import UpdateBlog from './pages/AdminDashboard/Menus/Blog/UpdateBlog';
import UpdateCategory from './pages/AdminDashboard/Menus/categories/UpdateCategory';

// const CreateBlog = lazy(() => import('./pages/AdminDashboard/Menus/magazines/CreateBlog'));
const ThankYou = lazy(() => import('./pages/ThankYou'));
const ChangePassword = lazy(() => import('./pages/AdminDashboard/Menus/users/ChangePassword'));
const EMAILOTP = lazy(() => import('./pages/EMAILOTP'));
const Login = lazy(() => import('./pages/AdminDashboard/AuthComponents/Login'));
const Dashboard = lazy(() => import('./pages/AdminDashboard/Layout/Dasboard'));
const ForgotPassword = lazy(() => import('./pages/AdminDashboard/AuthComponents/ForgotPassword'));
const NewPassword = lazy(() => import('./pages/AdminDashboard/AuthComponents/NewPassword'));
const ErrorPage = lazy(() => import('./pages/AdminDashboard/AuthComponents/Error'));
const Body = lazy(() => import('./pages/AdminDashboard/Layout/Body'));
const UserList = lazy(() => import('./pages/AdminDashboard/Menus/users/UserList'));
const Shimmer = lazy(() => import('./pages/AdminDashboard/Shimmer'));
const Agentmanagment = lazy(() => import('./pages/AdminDashboard/Menus/Agent/Agentmanagment'));
const Documents = lazy(() => import('./pages/AdminDashboard/Menus/Documents/Documents'));
const UploadDocument = lazy(() => import('./pages/AdminDashboard/Menus/Documents/UploadDocument'));
const AddAgent = lazy(() => import('./pages/AdminDashboard/Menus/Agent/AddAgent'));
const Institutionlist = lazy(() => import('./pages/AdminDashboard/Menus/Institutionlist/Institutionlist'));
const ViewDocument = lazy(() => import('./pages/AdminDashboard/Menus/Documents/ViewDocument'));
const EditDocument = lazy(() => import('./pages/AdminDashboard/Menus/Documents/EditDocument'));
const UserRegistration = lazy(() => import('./pages/AdminDashboard/Menus/users/UserRegistration'));
const EditUser = lazy(() => import('./pages/AdminDashboard/Menus/users/EditUser'));
const EditInstituteUser = lazy(() => import('./pages/AdminDashboard/Menus/users/EditInstituteUser'));
const CreateInstitute = lazy(() => import('./pages/AdminDashboard/Menus/Institutionlist/CreateInstitute'));
const EditInstitute = lazy(() => import('./pages/AdminDashboard/Menus/Institutionlist/EditInstitute'));
const AddInstituteUser = lazy(() => import('./pages/AdminDashboard/Menus/Institutionlist/AddInstituteUser'));
const ViewInstituteUser = lazy(() => import('./pages/AdminDashboard/Menus/Institutionlist/ViewInstituteUser'));
const OTP = lazy(() => import('./pages/OTP'));
const SharedDocuments = lazy(() => import('./pages/AdminDashboard/Menus/Documents/SharedDocuments'));
const Logout = lazy(() => import('./pages/AdminDashboard/Layout/Logout'));

const appRouter = createHashRouter([
  {
    path: '/',
    element: <Suspense fallback={<div>Loading...</div>}><Login /></Suspense>
  },
  {
    path: '/share/document/:fileuuid/:institutionuuid',
    element: <Suspense fallback={<div>Loading...</div>}><OTP /></Suspense>
  },
  {
    path: '/directshare/document/:fileuuid/:email',
    element: <Suspense fallback={<div>Loading...</div>}><EMAILOTP /></Suspense>
  },
  {
    path: '/logout',
    element: <Suspense fallback={<div>Loading...</div>}><Logout /></Suspense>
  },
  {
    path: '/filedownlaod/:uuid',
    element: <Suspense fallback={<div>Loading...</div>}><OTP /></Suspense>
  },
  {
    path: '/dashboard',
    element: <Suspense fallback={<div>Loading...</div>}><Dashboard /></Suspense>,
    children: [
      {
        path: 'shimmer',
        element: <Suspense fallback={<div>Loading...</div>}><Shimmer /></Suspense>
      },
      {
        path: '',
        element: <Suspense fallback={<div>Loading...</div>}><Body /></Suspense>
      },
      {
        path: 'changePassword',
        element: <Suspense fallback={<div>Loading...</div>}><ChangePassword /></Suspense>
      },
      {
        path: 'categories/:menuId',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><Categories /></Suspense>
          </AuthChecker>
        )
      },
      {
        path: 'posts/:menuId',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><Blogs /></Suspense>
          </AuthChecker>
        )
      },
      {
        path: 'posts/:menuId/blog/:id',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><UpdateBlog /></Suspense>
          </AuthChecker>
        )
      },
      {
        path: 'posts/:menuId/create-blog',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><CreateBlog /></Suspense>
          </AuthChecker>
        )
      },
      {
        path: 'casestudy/:menuId',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><CaseStudy /></Suspense>
          </AuthChecker>
        )
      },
      {
        path: 'casestudy/:menuId/create-case-study',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><CrateCaseStudy /></Suspense>
          </AuthChecker>
        )
      },
      {
        path: 'casestudy/:menuId/create-case-study/:id',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><UpdateCaseStudy /></Suspense>
          </AuthChecker>
        )
      },
      {
        path: 'categories/:menuId/create-cateory',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><CreateCategory /></Suspense>
          </AuthChecker>
        )
      },
      {
        path: 'categories/:menuId/categories/:id',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><UpdateCategory /></Suspense>
          </AuthChecker>
        )
      }
    ]
  },
  {
    path: '/login',
    element: <Suspense fallback={<div>Loading...</div>}><Login /></Suspense>
  },
  {
    path: '/thankyou',
    element: <Suspense fallback={<div>Loading...</div>}><ThankYou /></Suspense>
  },
  {
    path: '/changepassword/:id',
    element: <Suspense fallback={<div>Loading...</div>}><NewPassword /></Suspense>
  },
  {
    path: '/forgotpassword',
    element: <Suspense fallback={<div>Loading...</div>}><ForgotPassword /></Suspense>
  },
  {
    path: '*', // Catch-all for unmatched routes
    element: <Suspense fallback={<div>Loading...</div>}><ErrorPage /></Suspense>
  }
]);

function App() {
  const authCtx = useContext(AuthContext);
  const authRole = authCtx.role;

  let decryptedRole;
  if (authRole !== 'superadmin' && authRole !== 'employee' && authRole !== 'institueStaff') {
    const decryptUserRole = (encryptedRole: string | CryptoJS.lib.CipherParams | null, key: string | CryptoJS.lib.WordArray) => {
      if (!encryptedRole) {
        return null; // or handle appropriately if null encryptedRole is not expected
      }
      const bytes = CryptoJS.AES.decrypt(encryptedRole, key);
      return bytes.toString(CryptoJS.enc.Utf8).trim(); // Trim the decrypted role
    };

    decryptedRole = decryptUserRole(authRole, '98760');
  }

  let resizeTimeout: number | null;

  function handleResize() {
    if (!resizeTimeout) {
      resizeTimeout = requestAnimationFrame(() => {
        resizeTimeout = null;
      });
    }
  }

  useEffect(() => {
    handleResize();
  }, []);

  return (
    <div className="App">
      <RouterProvider router={appRouter} />
    </div>
  );
}

export default App;
