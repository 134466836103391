import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../../../authContext";
import { toast } from "react-toastify";
import Shimmer from "../../Shimmer";
import { useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const UpdateCategory: React.FC = () => {
    const authContext = useContext(AuthContext);
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const { menuId, id } = useParams(); // Assuming 'id' is the category UUID from the URL

    const [categoryName, setCategoryName] = useState("");
    const [categoryDescription, setCategoryDescription] = useState("");
    const [isId, setIsId] = useState("");
    useEffect(() => {
        fetchCategoryDetails();
    }, []);

    const fetchCategoryDetails = async () => {
        setLoader(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API}/pipeline`, {
                filter: {
                    filter: {
                        logic: "and",
                        offset: 0,
                        limit: 1,
                        filters: [
                            {
                                field: "uuid",
                                operator: "eq",
                                table: "Categories",
                                value: id
                            }
                        ]
                    }
                },
                menuId,
                action: "command",
                command: [
                    {
                        agent: "categoriesList",
                        appName: "fileSharing",
                        folder: "Posts/Categories"
                    }
                ]
            }, {
                headers: {
                    'x-access-token': authContext.token,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200 && response.data.status === "success") {
                const category = response.data.data[0];
                setCategoryName(category.name || "");
                setCategoryDescription(category.description || "");
                setIsId(category._id || "");
            } else {
                toast.error('Failed to load category details!');
            }
        } catch (error: any) {
            toast.error('Failed to load category details! ' + error.message);
        } finally {
            setLoader(false);
        }
    };

    const resetForm = () => {
        setCategoryName("");
        setCategoryDescription("");
    };

    const isFormValid = () => {
        return categoryName && categoryDescription;
    };

    const submitCategory = async () => {
        setLoader(true);

        const payload = {
            id: isId, // Use the UUID to update the correct category
            name: categoryName,
            description: categoryDescription,
            menuId,
            action: "command",
            command: [
                {
                    agent: "updateCategories",
                    appName: "fileSharing",
                    folder: "Posts/Categories"
                }
            ]
        };

        const config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API}/pipeline`,
            headers: {
                'x-access-token': authContext.token,
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(payload)
        };

        try {
            const response = await axios.request(config);
            if (response.status === 200) {
                if (response.data.status === "success") {
                    toast.success(response.data.message || 'Category updated successfully!');
                    resetForm();
                    navigate(`/dashboard/categories/${menuId}`); // Replace with the actual path to the category list page
                } else {
                    toast.error(response.data.message || 'Something went wrong!');
                }
            } else {
                toast.error(response.data.message || 'Something went wrong!');
            }
        } catch (error: any) {
            toast.error('Something went wrong! ' + error.message);
        } finally {
            setLoader(false);
        }
    };

    return loader ? <Shimmer /> : (
        <div className="mb-9">
            <div className="row g-3 flex-between-end mb-5">
                <div className="col-auto">
                    <h2 className="mb-2">Update Category</h2>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary mb-2 mb-sm-0" type="button" onClick={submitCategory} disabled={!isFormValid()}>Update</button>
                </div>
            </div>
            <div className="row g-5">
                <div className="col-12 col-xl-8">
                    <h4 className="mb-3">Category Name <span className="text-danger">*</span></h4>
                    <input
                        className="form-control mb-5"
                        type="text"
                        placeholder="Enter category name..."
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                        aria-label="categoryName"
                    />
                    <h4 className="mb-3">Category Description <span className="text-danger">*</span></h4>
                    {/* <input
                        className="form-control mb-5"
                        type="text"
                        placeholder="Enter category description..."
                        value={categoryDescription}
                        onChange={(e) => setCategoryDescription(e.target.value)}
                        aria-label="categoryDescription"
                    /> */}
                    <CKEditor
                        editor={ClassicEditor}
                        data={categoryDescription}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setCategoryDescription(data);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default UpdateCategory;
